import { Shift } from "@/model/Shift";
import { Taker, TakerAvailability } from "@/model/Taker";
import { availabilityService } from "@services/availability.service";
import { shiftsService } from "@services/shift.service";
import { takersService } from "@services/takers.service";
import * as Moment from 'moment';
import { DateRange, extendMoment } from 'moment-range';
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

const moment = extendMoment(Moment);

import {
  WeekNavigator
} from "@/components";

@Options({
  name: 'TakerAvailabilities',
  components: {
    WeekNavigator
  }
})
export default class TakerAvailabilities extends Vue {
  @Prop()
  readonly taker!: Taker;

  @Prop()
  readonly takerId!: string;
  
  @Prop()
  readonly isLoading!: boolean;
  
  @Prop()
  readonly zones!: any[];

  range: DateRange = null;

  shifts: Shift[] = null;

  availabilities: TakerAvailability[] = null;

  days = null;

  onUpdateRange() {
    this.days = [...this.range.by('day')];
    this.loadTakerAvailabilities();
  }

  getWeekDay(day: Moment.Moment) {
    return day.format('dddd').capitalize();
  }

  getFormattedDate(day: Moment.Moment) {
    return day.format("DD/MM/YYYY");
  }

  avExists(shift_id: number, day: Moment.Moment) {
    const date = day.format("YYYY-MM-DD");

    return this.availabilities
      ?.find(a => a.shift_id === shift_id && a.date === date);
  }

  avStatus(shift_id: number, day: Moment.Moment) {
    const date = day.format("YYYY-MM-DD");

    return this.availabilities
      ?.find(a => a.shift_id === shift_id && a.date === date)
      ?.status;
  }

  async deleteAv(shift_id: number, day: Moment.Moment) {
    if (!this.isTMT) return;

    const date = day.format("YYYY-MM-DD");

    const av = this.availabilities
      ?.find(a => a.shift_id === shift_id && a.date === date);

    await availabilityService.deleteAvailability(av.id);

    const idx = this.availabilities.indexOf(av);
    this.availabilities.splice(idx, 1);

    this.$successMessage("Disponibilità eliminata");
  }

  async addAvailability(shift_id: number, day: Moment.Moment) {
    const response = await availabilityService.createAvailability({
      date: day.format("YYYY-MM-DD"),
      entity_id: +this.takerId,
      shift_id,
      zone_id: this.taker?.zones[0]?.id
    });

    const date = day.format("YYYY-MM-DD");

    const idx = this.availabilities
      ?.findIndex(a => a.shift_id === shift_id && a.date === date);

    if (idx !== -1) {
      this.availabilities[idx] = response;
    } else {
      this.availabilities.push(response);
    }

    this.$successMessage("Disponibilità aggiunta");
  }

  private initRange() {
    this.range = moment.range(
      moment().startOf('week'),
      moment().endOf('week')
    );

    this.days = [...this.range.by('day')];
  }

  private async loadShifts() {
    this.shifts = await shiftsService.getAll();
  }

  private async loadTakerAvailabilities() {
    this.availabilities = await takersService.shiftAvailabilities(
      +this.takerId,
      this.range.start.format("yyyy-MM-DD"),
      this.range.end.format("yyyy-MM-DD"),
    );
  }

  get isNew() {
    return this.takerId === 'new';
  }

  @Watch('taker', {immediate: true})
  onTakerChange() {
    this.initRange();
    this.loadShifts();

    if (!this.isNew) {
      this.loadTakerAvailabilities();
    }

  }
}