
import { computed, defineComponent, onErrorCaptured, onMounted, provide, ref, watch } from "vue";
import { useI18n }                                                                    from "vue-i18n";
import { useRoute, useRouter }                                                        from "vue-router";

import { useForm } from "vee-validate";

import { TakersRoutesEnum } from "../../router";
import { ZoneRelation }     from "@/model/Zone";
import { Taker }            from "@/model/Taker";
import { useMessage }       from "@plugins/Messages-plugin";
import { takersService }    from "@services/takers.service";

import { ButtonSendMessage, PageHeader, SendMessageDialog, } from "@/components";
import { UserRoleEnum }                                      from "@/model/User";
import { Error422 }                                          from "@/model/common/Error422";

export default defineComponent( {
  name: 'TakerPage',

  components: {
    PageHeader,
    SendMessageDialog,
    ButtonSendMessage
  },

  props: ['takerId'],

  setup(props) {
    const isLoading = ref(false)

    const { confirmMessage, successMessage, errorMessage } = useMessage()

    const { t: $t } = useI18n()

    const route  = useRoute()
    const router = useRouter()

    const backRoute = { name: TakersRoutesEnum.TAKERS_LIST }

    const isNew = computed(() => props.takerId === 'new' )

    const taker     = ref<Taker>()
    const zones     = ref<Array<any>>()

    const tabs  = computed<any>(() => [
      {
          label: $t( 'taker.tabs.data' ),
          icon : 'fas fa-id-card',
          to   : { name: TakersRoutesEnum.TAKERS_DETAIL },
          class: route.name === TakersRoutesEnum.TAKERS_DETAIL ? 'p-highlight' : ''
      },
      {
          label: $t( 'taker.tabs.zone' ),
          icon : 'fas fa-map',
          to   : { name: TakersRoutesEnum.TAKERS_ZONE },
          class: route.name === TakersRoutesEnum.TAKERS_ZONE ? 'p-highlight' : '',
          disabled: isNew.value
      },
      {
          label   : $t( 'taker.tabs.availabilities' ),
          icon    : 'fas fa-clipboard-list',
          to      : { name: TakersRoutesEnum.TAKERS_AVAILABILITIES },
          class   : route.name === TakersRoutesEnum.TAKERS_AVAILABILITIES ? 'p-highlight' : '',
          disabled: isNew.value
      }
    ])

    const passwordRequired = ref(true)

    const validationSchema = computed(() => ({
      user_name   : 'required',
      user_surname: 'required',
      user_email  : 'required|email',

      password        : passwordRequired.value ? 'required|min:6' : null,
      confirm_password: passwordRequired.value ? 'required|confirmPwd:@password' : null,

      business_name : 'required',
      business_tel  : 'required',
      email         : 'required|email',
      pec           : 'required|email',

      business_sdi: null,

      vat         : 'requiredThisOr:fiscal_code',
      fiscal_code : 'requiredThisOr:vat',

      business_city_name  : 'required',
      business_address    : 'required',
      business_postal_code: 'required',
      business_province   : null,
      business_state      : null,

      sdi: null,

      is_active           : null,
      perc_fee            : null,
      cost_avg_delivery   : null,
      is_time_cost_active : null,
      is_ignore_cost_zone : null,
      is_perc_fee_active  : null,
    }))

    const {
            values,
            setValues,
            handleSubmit
          } =
              useForm( { validationSchema } )

    watch(values, (v) => {
      passwordRequired.value = v.password?.length > 0
    })

    const onSubmit = handleSubmit( async (v) => {
          const {
                  user_name, user_surname, user_email,
                  password, confirm_password,
                  ...others
                } = v;

      const takerBody: Taker = {
        ...taker.value,
        ...others,

        name   : user_name,
        surname: user_surname,

        users: [
          {
            id       : taker.value?.users?.[0].id,
            name     : user_name,
            surname  : user_surname,
            email    : user_email,
            is_active: v.is_active,
          role      : UserRoleEnum.TAKER
        }],

        shift_costs: taker.value.shift_costs,
        zones: zones.value,
        zone_custom_pricing: zones.value
          .map( z => z.custom )
          .filter( x => x ),

        vehicle_data: taker.value?.vehicle_data,
        start_lat: taker.value?.start_lat,
        start_lon: taker.value?.start_lon,
      }

      if ( password && (password === confirm_password)) {
        takerBody.users[0].password = password
      }

      let response;

      if (taker.value?.id) {
          response = await takersService.updatePatch( takerBody );
      } else {
          response = await takersService.create( takerBody );

          await router.replace( {
              name: TakersRoutesEnum.TAKERS_DETAIL,
            params: { takerId: response.id }
          } );
      }

          loadTaker();

          // taker.value     = response;

          successMessage( $t( 'message.success_message' ) );

        },
        ( e ) => {
          console.error( e )
          errorMessage( $t( 'common.required_fields' ) );
        } )


    function onDelete() {
        const { name, surname } = taker.value;

        confirmMessage(
            `Confermare la cancellazione del taker: ${ name } ${ surname } ?`,
            "Cancellazione taker"
        ).then( async (response) => {
          if (response) {
            await takersService.remove( taker.value );

            router
              .replace( { name: TakersRoutesEnum.TAKERS_LIST } )
              .then( () => {
                  successMessage( $t( 'message.success_message' ) );
              });
          }
        })
    }

    const loading = ref(false)
    provide("loading", loading)

    async function loadTaker() {
      try {
        taker.value = new Taker()
        taker.value = await takersService.getById( +props.takerId )

        initFormValues()

      } catch (error) {
        errorMessage("Caricamento taker non riuscito")
      } finally {
        isLoading.value = false
      }
    }

    function getCustomPricingForZone( zone: ZoneRelation ) {
        return taker.value?.zone_custom_pricing?.find(
            x => x.zone_id === zone.id
        );
    }

    function initFormValues() {
      setValues( {
        user_name   : taker.value.users[0]?.name,
        user_surname: taker.value.users[0]?.surname,
        user_email  : taker.value.users[0]?.email,
        ...taker.value
      } )
    }

    function handle422({ errors }: Error422) {
      const fieldsInError = Object.keys(errors)

      function flattenString(a: string[]) {
        return a?.join(" ")
      }

      if (fieldsInError?.length) {
        fieldsInError.forEach(k => {
          errorMessage(
            flattenString(errors[k])
          )
        })
      }
    }

    onErrorCaptured((e: any) => {
      if (e.status === 422) {
        handle422(e.data);
        return false
      }
    })

    onMounted(async () => {
      isLoading.value = true;

      if (isNew.value) {
          taker.value     = new Taker();
          isLoading.value = false;
      } else {
          await loadTaker();
      }

      initFormValues()

      zones.value = taker.value?.zones?.map( z => {
          return {
              ...z,
              custom: getCustomPricingForZone( z )
          }
      } ) || [];
    })

    return {
      validationSchema,
      values,
      taker, zones,
      backRoute,
      isNew,
      tabs,
      isLoading,
      onSubmit,
      onDelete
    }

  }
})
