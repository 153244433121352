import { defineComponent, h } from "vue";
import { Options, Vue }       from "vue-class-component";
import { FilterMatchMode }    from "primevue/api";
import { TakersRoutesEnum }   from "../../router";
import { takersService }      from "@services/takers.service";
import { SessionStorageEnum } from "@/utils/SessionStorageEnum";
import { Taker }              from "@/model/Taker";
import CustomCellMixin        from "@components/TableCustomCells/CustomCellMixin";
import { PageHeader }         from "@/components";
import { DynamicTable }       from "@sharedComponents";

@Options( {
  components: {
    DynamicTable,
    PageHeader
  },
  beforeRouteEnter( to, from, next ) {
    if (from.name && !(from.name as string).startsWith( 'takers' )) {
      sessionStorage.removeItem( SessionStorageEnum.TABLE_STATE_TAKERS );
    }

    next();
  },
  beforeRouteLeave() {

    if (this.service?.cancelPendingRequests) {

      this.service.cancelPendingRequests();
    }
  }
} )
export default class TakersPage extends Vue {
  filters: any = null;

  get service() {
    return takersService;
  }

  get stateKey() {
    return SessionStorageEnum.TABLE_STATE_TAKERS
  }

  get newTakerRoute() {
    return {
      name  : TakersRoutesEnum.TAKERS_DETAIL,
      params: { takerId: 'new' }
    }
  }

  get cols() {
    return [
      {
        field      : 'name',
        header     : 'Nome',
        placeHolder: "Cerca per nome"
      },
      {
        field      : 'surname',
        header     : 'Cognome',
        placeHolder: "Cerca per cognome"
      },
      {
        field : 'business_tel',
        header: 'Telefono'
      },
      {
        field      : 'business_city_name',
        placeHolder: "Città inizia per...",
        header     : 'Città'
      },
      {
        field      : 'business_address',
        header     : 'Indirizzo',
        placeHolder: "Cerca per indirizzo"
      },
      {
        header   : 'Tipologia',
        component: defineComponent( {
          mixins: [ CustomCellMixin ],
          render() {
            const t = this.data as Taker;

            const icons = t.vehicle_data.map( vd => {
              const { icon, name_it } = vd.vehicle;

              return h(
                  'i',
                  {
                    class: [ `fa fa-${ icon } p-mr-2` ],
                    title: name_it
                  }
              );
            } );

            return h(
                'div',
                {
                  class: [ 'p-d-flex' ]
                },
                icons
            )
          }
        } )
      },

    ]
  }

  goToDetail( taker: Taker ) {
    this.$router.push( {
      name  : TakersRoutesEnum.TAKERS_DETAIL,
      params: { takerId: taker.id }
    } );
  }

  created() {
    this.initFilter();
  }

  private initFilter() {
    this.filters = {
      global            : {
        value    : null,
        matchMode: FilterMatchMode.CONTAINS
      },
      name              : {
        value    : "",
        matchMode: FilterMatchMode.CONTAINS,
      },
      surname           : {
        value    : "",
        matchMode: FilterMatchMode.CONTAINS,
      },
      email             : {
        value    : "",
        matchMode: FilterMatchMode.CONTAINS,
      },
      business_address  : {
        value    : null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      business_name     : {
        value    : "",
        matchMode: FilterMatchMode.CONTAINS,
      },
      business_city_name: {
        value    : "",
        matchMode: FilterMatchMode.STARTS_WITH,
      },
    };
  }

}
