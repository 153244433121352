
import { defineComponent, computed, ref } from "vue"

import {
  AddressAutocomplete,
  CityAutocomplete,
  VCheckbox,
  VInputSwitch,
  VInputText,
  VPassword,
  VTextArea,
} from "@/components";
import { useField } from "vee-validate";

export default defineComponent({
  props: ['taker', 'takerId', 'isLoading', 'zones'],

  components: {
    AddressAutocomplete,
    CityAutocomplete,
    VCheckbox,
    VInputSwitch,
    VInputText,
    VPassword,
    VTextArea
  },

  setup() {
    const { 
      value: business_city_id 
    }   = useField<number>('business_city_id')
    const { 
      value      : business_city_name,
      meta       : business_city_meta,
      errors     : business_city_errors,
      setTouched : business_city_setTouched
    } = useField('business_city_name')

    const businessCity = computed({
      get() {
        return business_city_id.value && business_city_name.value
            ? { 
              id: business_city_id.value,
              name: business_city_name.value 
            } as any
            : business_city_name.value
      },
      set(value: any | string) {
        if( value) { 
          business_city_setTouched(true) 
        }
        if (typeof value === "string") {
          business_city_name.value = value
        }
        else {
          business_city_id.value     = value?.id
          business_city_name.value   = value?.name
        }
      },
    })


    const { 
      value      : business_address_value,
      meta       : business_address_meta,
      errors     : business_address_errors,
      setTouched : business_address_setTouched
    } = useField('business_address')

    
    const { value: business_province_value }    = useField('business_province')
    const { value: business_state_value }       = useField('business_state')
    const { value: business_postal_code_value } = useField('business_postal_code')

    const business_address = computed({
      get() {
        return business_address_value.value
            ? { 
              description: business_address_value.value,
            } as any
            : business_address_value.value
      },
      set(value: any | string) {
        if( value) { 
          business_address_setTouched(true) }
        if (typeof value === "string") {
          business_address_value.value = value
        }
        else {
          business_address_value.value   = value?.name
        }
      },
    })
    const geocodeResult = ref<google.maps.GeocoderResult>();
    function onSelectAddress(item: google.maps.GeocoderResult) {
      geocodeResult.value = item;

      const province   = getProvince(item);
      const postalCode = getPostalCode(item);
      const state      = getState(item);

      business_postal_code_value.value = postalCode;
      business_province_value.value    = province;
      business_state_value.value       = state;
    }

    
    function getState(g: google.maps.GeocoderResult) {
      return g.address_components
        .find(x => x.types?.includes("country"))
        ?.long_name;
    }

    function getProvince(g: google.maps.GeocoderResult) {
      return g.address_components
        .find(x => x.types?.includes("administrative_area_level_2"))
        ?.short_name;
    }

    function getPostalCode(g: google.maps.GeocoderResult) {
      return g.address_components
        .find(x => x.types?.includes("postal_code"))
        ?.short_name;
    }


    return {
      businessCity,
      business_city_meta,
      business_city_errors,

      business_address,
      business_address_meta,
      business_address_errors,
      onSelectAddress
    }
  }
})
