import { toRaw }        from "vue";
import { Options, Vue } from "vue-class-component";
import { Prop, Watch }  from "vue-property-decorator";

import {
  AddressAutocomplete,
  ZoneAutocompleteSelect,
  ZonePricingGrid,
  ZoneVehicleData,
  ZoneShiftCosts
} from "@/components";

import { Taker }             from "@/model/Taker";
import { Zone, ZoneCustomPricing, ZoneRelation } from "@/model/Zone";
import { mapStyles } from "@/utils/gmap_utils";

@Options({
  name: 'TakerZone',
  components: {
    AddressAutocomplete,
    ZoneAutocompleteSelect,
    ZonePricingGrid,
    ZoneVehicleData,
    ZoneShiftCosts
  },
})
export default class TakerZone extends Vue {
  @Prop() readonly taker!     : Taker;
  @Prop() readonly takerId!   : string;
  @Prop() readonly isLoading! : boolean;

  @Prop({ default: () => [] }) 
  readonly zones! : Zone[];

  get hasZones() {
    return !!this.zones?.length;
  }

  shapePricingConfig = [
    {
      title      : 'Area #1',
      costLabel  : 'Costo',
      costKey    : 'cost_shape1'
    },
    {
      title      : 'Area #2',
      costLabel  : 'Costo',
      costKey    : 'cost_shape2'
    },
    {
      title      : 'Area #3',
      costLabel  : 'Costo',
      costKey    : 'cost_shape3'
    },
    {
      title      : 'Area #4',
      costLabel  : 'Costo',
      costKey    : 'cost_shape4'
    },
    {
      title      : 'Area #5',
      costLabel  : 'Costo',
      costKey    : 'cost_shape5'
    },

  ];

  //#region GESTIONE_ZONE
  get zone() {
    return this.zones?.[0] as any;
  }

  onSelectZone(z) {
    this.zones.push(z);
  }

  createCustom(data) {
    const keys = [
      "cost_shape1",
      "cost_shape2",
      "cost_shape3",
      "cost_shape4",
      "cost_shape5",

      "cost_km_flat",
    ];

    const custom: ZoneCustomPricing = {
      zone_id: data.id
    };

    keys.forEach(k => {
      custom[k] = data[k]
    });

    data.custom = custom;
  }
  
  restoreStandard(data) {
    delete data.custom; 
  }


  removeZone(data) {
    this.$confirmMessage("Sicuro di voler rimuovere questa zona?")
      .then(r => {
        if (r) {
          const idx = this.zones.indexOf(data);
          this.zones.splice(idx, 1);
        }
      });
  }

  //#endregion

  //#region Punto di partenza
  startAddress: string = null;
  addressResult: google.maps.GeocoderResult = null;

  onSelectStartAddress(item: google.maps.GeocoderResult) {
    this.addressResult = item;

    this.taker.start_lat = item.geometry.location.lat();
    this.taker.start_lon = item.geometry.location.lng();

    this.drawMarker(
      this.taker.start_lat,
      this.taker.start_lon
    );
  }
  //#endregion

  
  getCustomPricingForZone(zone: ZoneRelation) {
    return this.taker?.zone_custom_pricing?.find(
      x => x.zone_id === zone.id
    );
  }

  mapRef: google.maps.Map = null;

  marker: google.maps.Marker = null;

  get map() {
    return toRaw(this.mapRef);
  }

  private drawMarker(lat: number, lng: number) {
    if (this.marker) {
      this.marker.setPosition(new google.maps.LatLng({ lat, lng }))
    } else {
      this.marker = new google.maps.Marker({
        map: this.map,
        position: new google.maps.LatLng({ lat, lng }),
        draggable: true,
      });

      this.marker.addListener('dragend', (e) => {
        this.taker.start_lat = e.latLng.lat();
        this.taker.start_lon = e.latLng.lng();
      });
    }

    this.map.setZoom(20);
    this.map.setCenter(this.marker.getPosition());
  }

  private _buildMap() {
    const element = (this.$refs.mapEl as HTMLElement); 
    if (!element) return;

    this.mapRef = new google.maps.Map(
      element,
      {
        center : this.$config.startCenter,
        zoom   : this.$config.startZoom,
        styles : mapStyles
      },
    );

    this.map.data.setStyle({
      strokeWeight  : 1,
      strokeOpacity : 1,
      strokeColor   : "#3399FF",
      fillColor     : "#3399FF",
      fillOpacity   : 0.2,
      editable      : false,
      draggable     : false,
      clickable     : true,
      zIndex        : 1,
    });

    const { start_lat, start_lon } = this.taker;
    if (start_lat && start_lon) {
      this.drawMarker(start_lat, start_lon);
    }
  }

  @Watch('zone', {immediate: true}) 
  onZoneChange(value){

    if (!value) {
      return;
    }

    this.$nextTick(() => {
      this.mapRef = null; 
      this._buildMap();
    });
  }

}