import { EntityWithCosts } from "./Entity";
import { Shift } from "./Shift";
import { ShiftAvailabilityStatus } from "./ShiftAvailability";
import { User } from "./User";
import { VehicleData } from "./Vehicle";
import { Zone } from "./Zone";

export class Taker extends EntityWithCosts {
    users?: User[] = [
        new User()
    ];

    start_lat: number;
    start_lon: number;

    last_order    ?: {
        dropoff_lat : number;
        dropoff_lon : number;
    }

}

export interface TakerAvailability {
    id: number;
    entity_id: number;

    zone_id: number;
    zone: Zone;

    shift_id: number;
    shift: Shift;

    date: string;
    status: ShiftAvailabilityStatus;
}


export interface AvailableTaker  {
    id            : number;
    name          : string;
    surname       : string;
    business_name : string;
    start_lat     : number;
    start_lon     : number;
    last_order    : {
        dropoff_lat : number;
        dropoff_lon : number;
    }
    vehicle_data : VehicleData[];
}
